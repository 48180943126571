
.marque img{
  width: 200px;
  height: 140px;
}


.title {
  font-weight: 600;
  font-size: 18px;
}

.card-bodye img{
  width: 100px;
  height: 100px;
  margin-top: 10px;
}



.btn {
  border-radius: 10px;
  font-size: 18px;
  border: 1px solid black;
  transition: 0.5s ease;
}

.btn:hover {
  background-color: var(--primary-color);
  color: white;
  border: 1px solid white;
}

.service_p h1 {
  font-size: 1.5rem;
  font-weight: 600;

}


.ser {
  padding-left: 40px;

}

.paused {
  animation-play-state: paused;
}


.navlink {
  background-color: rgb(220, 235, 248);
  margin-top: 10px;
  padding-bottom: 20px;


}

.navlink li {
  padding-top: 35px;
  /* Adjust margin as needed */
  font-size: 1.2rem;
  padding-bottom: 5px;
}


.navlink a.active {
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;

}

.navlink a.inactive {
  color: grey;
  font-weight: 1.2rem;
}

.title {
  font-size: 2rem;
  font-weight: 600;
}

.titre {
  font-size: 1.5rem;
  font-weight: bold;

}

.reactitre {
  font-weight: 600;
  font-size: 2rem;
  
}

.slide img{
  width: 10rem;
  margin: 0 40px;
}

.mobile {
  background-color:  rgb(218, 244, 255);
}

.mobile img{
  width: 10rem;
  border: 1px solid white; 
  border-radius: 10px;
  background-color: white;
}

.tec {
  gap: 20px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.tec button {
  border-radius: 10px;
  font-size: 18px;
  padding: 10px 8px;
  background-color: var(--primary-color);
  color: white;
  border: 1px solid transparent;
  transition: 0.5s ease;
}

.tec button.active {
  background-color: red;
}

.prix {
  gap: 20px;
}

.prix button {
  border-radius: 10px;
  font-size: 18px;
  padding: 10px 8px;
  background-color: var(--primary-color);
  color: white;
  border: 1px solid transparent;
  transition: 0.5s ease;
}

.prix button:hover {
  background: transparent;
color: var(--primary-color);
border: 1px solid white;
}

.call {
  background-color: rgb(83, 9, 9);
  color: white;
  margin-top: 20px;
  padding-bottom: 20px;
  justify-content: center;
  text-align: center;
}

.callnow {
  font-size: 22px;
}

.call li {
  padding: 20px;
  font-size: 20px;
  font-weight: 600;
}

.entete {
  text-align: center;
}

.entete span {
  font-size: 20px;
  font-weight: 600;
}

.imgreseau img{
 max-height: 35vh;
 min-height: 35vh;
  border-radius: 10px;
}

.reseau {
  border: 5px solid white;
  background-color: white;
  padding: 10px;
}


.reseau1 {
  border: 5px solid white;
  background-color: white;
  padding: 10px;
}

.serimg img{
  height: 80vh;
}

.entete {
  padding-bottom: 20px;
}

.seroffre {
  font-size: 1.5rem;
  font-weight: 500;
}

.demo {
  background-color: black;
}

.demo video{
  width: 100%;
  height: 600px;
}


/*********banner img **************/



@media only screen and (max-width:768px) {

  .message {
    font-size: 0.8;
    font-weight: 500;
    color: black;
  }
  
.reseau1 {
  border: 1px solid whitesmoke;
  background-color: whitesmoke;
  padding: 10px;
}

.titre {
  font-size: 1.2rem;
  font-weight: bold;
}

  .titre, .imgsite, .imgreseau , .text, .message {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 70vh;
  }
  
.imgreseau img{
  max-height: 25vh;
  min-height: 25vh;
  width: 60vh;
   border-radius: 5px;
 }
  
.reactitre {
  font-weight: 600;
  font-size: 1.7rem;
  
}

.serimg img{
  height: 100%;
  margin-bottom: 20px;

}

.imgsite img {
  height: 80vh;
  width: 80vh;
}

.service_p {
  margin-bottom: 80px;
}

}

@media only screen and (max-width:425px) {

  .titre {
    font-size: 1rem;
    font-weight: bold;
  }
  
    .titre, .imgsite, .imgreseau , .text, .message{
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 55vh;
    }
    
  .imgreseau img{
    max-height: 25vh;
    min-height: 25vh;
    width: 55vh;
     border-radius: 5px;
   }
    
  
  
.reactitre {
  font-weight: 600;
  font-size: 1.5rem;
  
}
  
.imgsite img {
  height: 50vh;
  width: 50vh;
}
.navlink a {
  padding-top: 35px;
  /* Adjust margin as needed */
  font-size: 0.9rem;
  padding-bottom: 5px;
}

.navlink a.inactive {
  color: grey;
  font-weight: 0.9rem;
}

  .ser {
    padding-left: 20px;
  
  }

  
.title {
  font-size: 1.3rem;
  font-weight: 600;
}

.seroffre {
  font-size: 1.1rem;
  font-weight: 500;
}


.demo {
  background-color: black;
}


.demo video{
  width: 100%;
  height: 250px;
}


}

@media only screen and (max-width:375px) {

  .titre {
    font-size: 0.9rem;
    font-weight: bold;
  }
  
    .titre, .imgsite, .imgreseau , .text, .message{
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 55vh;
    }
    
  .imgreseau img{
    max-height: 25vh;
    min-height: 25vh;
    width: 55vh;
     border-radius: 5px;
   }
   
   
.imgsite img {
  height: 50vh;
  width: 50vh;
}

.navlink a {
  padding-top: 35px;
  /* Adjust margin as needed */
  font-size: 0.8rem;
  padding-bottom: 5px;
}

.navlink a.inactive {
  color: grey;
  font-weight: 0.8rem;
}

.techno button {
  font-size: 12px;
}
  
  .service_p h1 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 10px;

  }

  .ser {
    padding-left: 20px;
  
  }
}


@media only screen and (max-width:360px) {

  .titre {
    font-size: 0.9rem;
    font-weight: bold;
  }
  
    .titre, .imgsite, .imgreseau , .text ,.message{
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 45vh;
    }
    
  .imgreseau img{
    max-height: 25vh;
    min-height: 25vh;
    width: 45vh;
     border-radius: 5px;
   }
   
.navlink a {
  padding-top: 35px;
  /* Adjust margin as needed */
  font-size: 0.8rem;
  padding-bottom: 5px;
}

.navlink a.inactive {
  color: grey;
  font-weight: 0.8rem;
}

.techno button {
  font-size: 12px;
}
  
  .service_p h1 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 10px;

  }

  .ser {
    padding-left: 20px;
  
  }
}

@media only screen and (max-width:320px) {

  .titre {
    font-size: 1rem;
    font-weight: bold;
  }
  
    .titre, .imgsite, .imgreseau , .text{
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 45vh;
    }
    
  .imgreseau img{
    max-height: 25vh;
    min-height: 25vh;
    width: 45vh;
     border-radius: 5px;
   }
  
.techno button {
  font-size: 10px;
}
  .card-bodye {
    width: 100%;
  max-height: 230px;
  } 
}