.footer{
    padding-top: 60px;
    padding-bottom: 30px;
    background-color: var(--primary-color);
}

.footer__text {
   font-size: 13px;
    color: rgba(255, 255, 255, 0.735);
    
}

.space {
  gap: 30px;
  display: block;
}

.abank {
  gap: 20px;
  display: flex;
  align-items: center;
  padding: 10px;
}

.footer__quick-links a :hover{
  color: var(--primary-color);
}


.footer__contact li span{
    font-size: 14px;
}

.footer__copyright{
    color: rgba(255, 255, 255, 0.735);
    font-size: 0.9rem;
    margin-top: 50px;
    text-align: center;
}

.pay img{
    transition: 0.5s ease;
   
  
  }
  
  .pay img:hover{
    transform: translateY(-8px);
  }
  
.visa img{
   width: 100%;
   height: 50px;
  }
 
   
.mobile img{
   width: 160%;
   height: 50px;
  
  }
  

  

.footer__text {
    line-height: 30px;
    color: rgba(255, 255, 255, 0.735);
    
}

.footer__quick-links ul li {
    background: transparent !important;
    font-size: 14px;
}


.quick__links-title {
    margin-bottom: 20px;
    color: #fff;
    font-size: 1.6rem;
}


.footer__contact li span{
    font-size: 1.2rem;
}

    
.call h1{
  font-size: 1.2rem;
  
}


.footer__copyright{
    color: rgba(255, 255, 255, 0.735);
    font-size: 0.9rem;
    margin-top: 50px;
    text-align: center;
}

  hr{
    color: white;
    
  }

  
  .app p {
    font-size: 14px;
  }

  .footer__quick-links p {
    font-size: 14px;
  }
  
@media only screen and (max-width:1024px) {
   
.footer__quick-links ul li {
  background: transparent !important;
  font-size: 12px;
}

.footer__quick-links p {
  font-size: 13px;
}


.app p {
  font-size: 12px;
}
 
}

@media only screen and (max-width: 768px) {
  .explora h1{ 
    font-size: 2rem;
  }
}


@media only screen and (max-width: 576px) {
  .explora h1{ 
    font-size: 2rem;
  }
  .phenitec p{
    font-size: 16px;
    
  }

  
.footer__quick-links a{
  background: transparent !important;
  font-size: 16px;
  
}


.footer__quick-links span{
  background: transparent !important;
  font-size: 16px;
  
}
}


@media only screen and (max-width: 320px) {
  .explora h1{ 
    font-size: 1.5rem;
  }
}