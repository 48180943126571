.video__section {
  position: relative;
  width: 100%;
  height: 50vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  
}

.video__section::before {

  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Superposition sombre */
  z-index: 1;
}

.video__background {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.content {
  position: relative;
  z-index: 2;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.data h2 {
  font-size: 2.5rem;
  font-weight: 500;
}

.clock__wrapper {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.clock__data {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.clock__data h1 {
  font-size: 3.5rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.clock__data h5 {
  font-size: 1.5rem;
  font-weight: 500;
}

.point {
  font-size: 2.5rem;
  font-weight: 500;
  color: #fff;
}



@media only screen and (max-width: 786px){

  
  .data h2 {
    font-size: 2rem;
    font-weight: 500;
  }

  
.point {
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
}


}




@media only screen and (max-width: 425px){

  .data h2 {
    font-size: 1.5rem;
    font-weight: 500;
  }


  
.clock__data h1 {
  font-size: 1.5rem;
  font-weight: 500;
}

  
.clock__data h5 {
  font-size: 1rem;
  font-weight: 500;
}


}