.fixed-header {
    margin-bottom: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000; 
}

.contain {
    display: flex;
    justify-content: space-between;
    gap: 200px;

}

.phenitec p{
    color: white;
    font-size: 1.1rem;

}

.footer__quick-links a{
    color: rgb(255, 255, 255);
    font-size: 1.1rem;
}


.footer__quick-links a:hover{
    color: rgb(12, 12, 12);
    font-size: 1.1rem;
}

.footer__quick-links span{
    color: rgb(255, 255, 255);
    font-size: 1.1rem;
}

.active {
    font-weight: 700;
    font-size: 1.1rem;

}


.inactive {
    font-weight: 500;
    font-size: 1.1rem;
}

.dropdown a{
    font-weight: 500;
    font-size: 1.1rem;
}
.navbar {
    height: 60px;
}


.menu {
    display: flex;
    column-gap: 2.5rem;
    margin-bottom: 0;



}

.nav-links {
    padding: 10px;
}

.pan {
    margin-left: 15px;
}

.nav__item {
    margin-right: 10px;
}

.nav__icons {
    display: flex;
    align-items: center;
    column-gap: 1.8rem;

}

.nav__icons span i {
    font-size: 1.4rem;
    color: var(--primary-color);
    cursor: pointer;
}

.navbar {
    background-color: #fff;
}

.mobile__menu {
    font-size: 1.3rem;
    color: var(--primary-color);
    display: none;
}

.contener {
    background-color: var(--primary-color);
}


.contact  {
    padding-top: 10px;
}

.contact  span{
    font-size: 1.1rem;
}

.email {
    padding: 10px;

}

.head {
    color: #fffdfd;
}

.group {
    padding-top: 5px;


}

.text {
    padding: 5px;

}


.logo {
    display: flex;
    align-items: center;
    margin-right: 250px;
}



.logo img {
    width: 50px;
    height: 50px;
}

.logo h1 {
    font-size: 1.2rem;
    font-weight: 700;
    color: var(--primary-color);

}

/* header.css */


.navbar .dropdown-menu {
    border-top: 4px solid rgb(12, 6, 92);
    /* Bordure rouge en haut du dropdown */
    width: 310px;
   
}

.navbar .dropdown-menu li {
    padding: 10px;
}

.dropdown span {
    font-weight: 500;
    font-size: 0.9rem;
}



.btn_Link:hover {
    background-color: rgb(12, 6, 92);
    padding: 5px;
    color: white;
}

.lang img {
    height: 20px;
    width: 20px;
} 


@media only screen and (max-width:1024px) {


    .logo {

        margin-right: 200px;

    }

    .navigation p {
        font-size: 14px;

    }

    .nav__wrapper a {
        font-size: 14px;
        padding: 5px;

    }




}



@media only screen and (max-width:992px) {


    .nav__item a {
        font-size: 12px;
    }

    .dropdown-menu a {
        font-size: 12px;
    }
}

@media only screen and (max-width:768px) {

    
.contact  {
    padding-bottom: 10px;
}

.navbar .dropdown-menu {
    border-top: 4px solid  rgb(12, 6, 92);
    /* Bordure rouge en haut du dropdown */
    width: 340px;
    padding: 10px;


}

.navbar .dropdown-menu li {
    padding: 2px;
}

.dropdown-menu  a {
    font-weight: 500;
    font-size: 0.7rem;
   
}

    .nav__wrapper a {
        font-size: 14px;
        padding: 2px;

    }


    .navigation {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(0, 0, 0, 0, 53);
        z-index: 9999;
        display: none;
    }

    .menu {
        position: absolute;
        top: 0px;
        right: 0;
        width: 500px;
        height: 100%;
        background: #fff;
        z-index: 99999;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .menu a {
        padding: 30px;
        text-align: center;
        font-size: 600;
        font-size: 20px;
    }

    

    .dropdown span {
        font-weight: 500;
        font-size: 20px;
    }

    .active__menu {
        display: block;
    }

    .mobile__menu {
        display: block;
    }

    .mobile__menu span i {
        font-size: 1.1rem !important;
    }

    .mobile__menu {
        display: flex;
    }


    .nav__wrapper {
        margin-bottom: 10px;

    }

    .logo {
       
        margin-right: 0px;
    }
    




}

@media only screen and (max-width:425px) {

    .contact  span{
        font-size: 0.9rem;
    }


    .menu {
        position: absolute;
        top: 0px;
        right: 0;
        width: 350px;
        height: 100%;
        background: #fff;
        z-index: 99999;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

   .navbar .dropdown-menu {
        width: 270px;
         padding-top: 10px;
         text-align: center;
        padding-left: 0px ;
       
    }

    .dropdown-menu a {
        font-size: 12px;
        font-weight: 600;
        padding-left: 0px ;
        margin-left: 0px;
    }

    .logo {

        margin-right: 0px;

    }


    .logo h1 {
        font-size: 1rem;
        font-weight: 700;
        color: var(--primary-color);

    }

    .menu a {
        padding: 30px;
        text-align: center;
        font-size: 600;
        font-size: 16px;
    }


    .dropdown span {
        font-weight: 500;
        font-size: 16px;
    }


}


@media only screen and (max-width:375px) {
    
    .contact  span{
        font-size: 0.8rem;
    }
    .menu {
        position: absolute;
        top: 0px;
        right: 0;
        width: 250px;
        height: 100%;
        background: #fff;
        z-index: 99999;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .navbar .dropdown-menu {
        width: 220px;
        padding-top: 10px;
    }

    .dropdown-menu a {
        font-size: 12px;
        font-weight: 600;
       
    }
}

@media only screen and (max-width:360px) {}


@media only screen and (max-width:320px) {
    .contact  span{
        font-size: 0.7rem;
    }

   

}