.hero__section {
    background: linear-gradient(#1b2E4b8f, rgba(0, 0, 0, 0.5)), 
                url('../assets/images/banner/bg.jpg') no-repeat center center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75vh;
    max-width: 100vw; /* Empêche le dépassement horizontal */
    overflow: hidden; /* Évite tout débordement */
    text-align: center;
    color: white;
    padding: 0 20px; /* Ajoute un padding pour éviter que le contenu colle aux bords */
    box-sizing: border-box; /* S'assure que padding ne cause pas de débordement */
}


.hero__section div {
    max-width: 800px;
    /* Ajuster la largeur du texte */
}

.home h2 {
    font-size: 2rem;
    margin-bottom: 15px;
    color: #e65c00;
}

.hero__section p {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.hero__section button {
    background-color: #ff6600;
    /* Couleur du bouton */
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
}

.hero__section button a {
    text-decoration: none;
    color: white;
}

.hero__section button:hover {
    background-color: #e65c00;
}


.padreal {
    padding: 40px;
}

.discute {
    padding-top: 80px;


}

.pub span {
    font-size: 1.2rem;
}


.cont {
    padding: 10px;
}

.carousel-inner img {
    width: 100% !important;
    height: 500px;
    left: 0;
}



.card-title {
    font-weight: 500;
    font-size: 10px;
}

.offre h2 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 30px;
}


.hero__section {
    padding: 0px;
}

.hero__section h2 {
    color: var(--primary-color);
    font-size: 2.5rem;
    font-weight: 600;
    margin: 20px 0px;
}

.hero__section p {
    color: var(--primary-color);
    line-height: 28px;

}

.hero__sectionp:nth-child(1) {
    font-weight: 500;
}

.butt {
    margin-top: 50px;
}

.button-link {
    border-radius: 10px;
    font-size: 18px;
    padding: 10px 8px;
    background-color: var(--primary-color);
    color: white;
    border: 1px solid transparent;
    transition: 0.5s ease;
}

.button-link:hover {
    background: transparent;
    color: var(--primary-color);
    border: 1px solid white;
}

.hero__content {
    padding-top: 45px;
}

.timer__count {
    background: var(--primary-color);
    height: 300px;
}

.timer__count img {
    width: 70% !important;
    height: 70% !important;
    object-fit: contain;
}

.store__btn {
    background: #fff;
    color: var(--primary-color);
    font-weight: 600;
}

.store__btn a:hover {
    color: var(--primary-color);
}

.realisation h1 {
    font-weight: 600;
}

/***********************Affiche et change de text **/

.animated-text {
    font-size: 34px;
    font-weight: 600;
    min-width: 280px;
    color: white;
}



@media only screen and (max-width: 992px) {
    .hero__content h2 {
        font-size: 2rem;
    }

    .section.title {
        font-size: 1.7rem;
    }

    .counter__img {
        display: none;
    }

    .clock__top__content {
        text-align: center;
    }

    .clock__top__content h4 {
        font-size: 0.9rem !important;
    }

    .clock__top__content h3 {
        font-size: 1rem !important;
    }

    .count__down-col {
        text-align: center;

    }


    .pagination a {
        font-size: 14px;
        padding: 8px;
        color: var(--primary-color);

    }

    .videosrc video {
        width: 80%;
        height: 70%;
        border-radius: 10px;
    }
}


@media only screen and (max-width: 768px) {
    .hero__content h2 {
        font-size: 1.6rem;
    }

    .hero__content p {
        font-size: 0.6rem;
    }

    .buy__btn a {
        font-size: 0.9rem;
    }

    .section__title {
        font-size: 1.4rem;
    }

    .animated-text {
        font-size: 20px;
        font-weight: 600;
        min-width: 280px;
        color: white;
        padding-bottom: 20px;
    }

    .card {
        margin-bottom: 20px;
    }

    .__section {
        padding: 70px 0px;
    }


}

@media only screen and (max-width: 576px) {
    .hero__content {
        padding-top: 0;
        margin-bottom: 40px;

    }

    .imghome img {
        padding: 20px;
    }

    .discute {
        padding: 20px;
    }


    .pub span {
        font-size: 1rem;
    }

    .padreal h1 {
        font-size: 1.4rem;
        font-weight: 600;
    }

    .button-link button {
        border-radius: 10px;
        font-size: 14px;
        padding: 8px 6px;
        background-color: var(--primary-color);
        color: white;
        border: 1px solid transparent;
        transition: 0.5s ease;
    }


    .videosrc video {
        width: 100%;
        height: 90%;
        border-radius: 10px;
    }


    .offre h2 {
        font-size: 25px;
        font-weight: 700;
    }

}


h6 {

    font-size: 24px;
    color: rgb(11, 11, 37);
}


video {
    width: 50%;
    height: 50%;
    border-radius: 10px;
}

/******************Produit ***************/

.auth h2 {
    font-size: 1.5rem;
    font-size: 600;
}

.desc {
    margin-bottom: 20px;
}

.auth_icon {
    width: 80px;
    height: 80px;
}

.fonction {
    margin-top: 50px;
}

.fonction .title {
    margin-bottom: 30px;
    font-family: 'Times New Roman', serif;
}

.fonction h2 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 20px;

}

.text-para {
    font-family: 'Times New Roman', serif;
    /* Police "Times New Roman" */
    font-size: 16px;
    /* Ajustez la taille de la police si nécessaire */
}

@media only screen and (max-width:800px) {

.authL {
    margin-bottom:  20px;
}
}


@media only screen and (max-width:525px) { 

    
.hero__section div {
    max-width: 400px;
    /* Ajuster la largeur du texte */
}

.home h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #e65c00;
}

.hero__section p {
    font-size: 1rem;
    margin-bottom: 20px;
}

.hero__section button {
    background-color: #ff6600;
    /* Couleur du bouton */
    color: white;
    border: none;
    padding: 8px 12px;
    font-size: 0.8rem;
    cursor: pointer;
    border-radius: 5px;
}

.hero__section button a {
    text-decoration: none;
    color: white;
}

.hero__section button:hover {
    background-color: #e65c00;
}


}
