.lien {
    display: flex;
    gap: 10px;
    margin-bottom: 30px;
    justify-content: center;
    align-items: center;
}

.lien a{
    font-size: 1.5rem;
    
}

.youtube {
    display: flex;
    justify-content: center;
    align-items: center;
}

.youtube img{
    width: 200px;
    height: 60px;
    border-radius: 10px;
    margin:  10px;
}
.youtube p {
    font-size: 1.5rem;
}



.manuel {
    padding: 100px;
}

.lienauto {
    color: #007bff;
    /* Bleu */
    text-decoration: none;
    /* Supprime le soulignement */
    font-weight: bold;
}

/* Style au survol */
.lienauto:hover {
    text-decoration: underline;
    /* Ajoute un soulignement au survol */
    color: #0056b3;
    /* Bleu plus foncé */
}

.nb {
    font-size: 22px;
    font-weight: 600;
    color: red;
}

.para {
    font-size: 18px;
    color: rgb(227, 85, 85);
}

.nben img {
    width: 400px;
    height: 250px;
}

@media only screen and (max-width: 768px) {

    .manuel {
        padding: 50px;
    }

    .logiciel {
        display: flex;
        flex-direction: row;
    }
}


@media only screen and (max-width: 525px) {

    .manuel {
        padding: 30px;
    }

    .manuel h2 {
        font-size: 24px;
        font-weight: 600;
    }

    .manuel .cal {
        margin-top: 20px;

    }

    .lien h2 {
        font-size: 20px;
        font-weight: 600;
    }

    .youtube p {
        font-size: 1.2rem;
    }
}


@media only screen and (max-width: 425px) {

    .youtube p {
        font-size: 1rem;
    }

    .youtube img{
        width: 200px;
        height: 60px;
        border-radius: 10px;
        margin:  4px;
    }

    .authL a{
        font-size: 1rem;
    }

    .manuel h3 {
        font-size: 1.2rem;
        padding-bottom: 10px;
    }

    .manuel {
        padding: 30px;
    }

    .manuel h2 {
        font-size: 1.4rem;
        font-weight: 600;
    }

    .manuel .cal {
        margin-top: 20px;

    }

    .lien h2 {
        font-size: 16px;
        font-weight: 600;
    }

    .lien a{
        font-size: 14px;
    }

    .fonction h2 {
        font-size: 1.2rem;
    }
}



@media only screen and (max-width: 375px) {

    .authL a{
        font-size: 1rem;
    }

    .manuel h3 {
        font-size: 1rem;
        padding-bottom: 10px;
    }

    .manuel {
        padding: 30px;
    }

    .manuel h2 {
        font-size: 1.2rem;
        font-weight: 600;
    }

    .manuel .cal {
        margin-top: 20px;

    }

    .lien h2 {
        font-size: 16px;
        font-weight: 600;
    }

    .lien a{
        font-size: 14px;
    }

    .fonction h2 {
        font-size: 1.2rem;
    }
    
    .manuel p {
        font-size: 12px;
    }


}

