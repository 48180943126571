.cardreal {
   min-height: 350px;
   max-height: 350px;
   padding: 10px;
   
}

.card-title {
 font-weight: 600;
 font-size: 20px;
}



.imgrod img {
  border: 1px solid rgb(12, 12, 12);
   border-radius: 40px;
   height: 80px;
   width: 80px;
}

@media only screen and (max-width:425px) {
   .cardreal {
      min-height: 400px;
      max-height: 400px;
      padding: 20px;
   }
   .reali .card {
      margin: 10px;
   }
}

@media only screen and (max-width:320px) {

   .card-bod {
      max-height: 200px;
      padding: 10px;
   } 

   
.padreal {
   padding: 20px;
}

 }